<template>
  <v-card
    color="transparent"
  >
    <v-card-title>
    <h3
      class="my-4"
      style="color: white !important;"
    >
      📝 {{$t('account.profile.billingDetails.title')}}
    </h3>
    </v-card-title>
    <v-row align="center" justify="center" no-gutters>

        <v-col cols="12" style="border-radius: 20px !important;background-color: rgba(255, 255, 255, 0.9) !important;">
          <v-form ref="form" v-model="valid" lazy-validation class="pa-6">

            <v-row no-gutters>

              <!-- Firstname -->
              <v-col cols="12" lg="6">
                <v-text-field
                  outlined
                  v-model="form.firstname"
                  :rules="requiredRules"
                  :label="$t('account.profile.billingDetails.form.firstname')">
                </v-text-field>
              </v-col>

              <!-- Name -->
              <v-col cols="12" lg="6">
                <v-text-field
                  outlined
                  v-model="form.name"
                  :rules="requiredRules"
                  :label="$t('account.profile.billingDetails.form.name')">
                </v-text-field>
              </v-col>

              <!-- Email -->
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="form.email"
                  :rules="requiredRules"
                  :label="$t('account.profile.billingDetails.form.email')">
                </v-text-field>
              </v-col>

              <!-- Company Name -->
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="form.company_name"
                  :label="$t('account.profile.billingDetails.form.company_name')">
                </v-text-field>
              </v-col>

              <!-- VAT Number -->
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="form.vat_number"
                  :label="$t('account.profile.billingDetails.form.vat_number')">
                </v-text-field>
              </v-col>

              <!-- Address -->
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="form.address.street"
                  :label="$t('account.profile.billingDetails.form.address')"
                  :rules="requiredRules">
                </v-text-field>
              </v-col>

              <!-- Postal code -->
              <v-col cols="12" lg="6">
                <v-text-field
                  outlined
                  v-model="form.address.postal_code"
                  :label="$t('account.profile.billingDetails.form.postal_code')"
                  :rules="requiredRules">
                </v-text-field>
              </v-col>

              <!-- City -->
              <v-col cols="12" lg="6">
                <v-text-field
                  outlined
                  v-model="form.address.locality"
                  :label="$t('account.profile.billingDetails.form.city')"
                  :rules="requiredRules">
                </v-text-field>
              </v-col>

              <!-- Country -->
              <v-col cols="12">
                <SelectCountries
                  :multiple="false"
                  :value="form.address.country"
                  v-on:onInput="(val) => {form.country = val;}"
                  :rules="requiredRules"/>
              </v-col>
            </v-row>

            <!-- Alert bar -->
            <v-alert
              :value="errors.form !== null"
              type="error"
              dismissible>
              {{errors.form}}
            </v-alert>
          </v-form>
        </v-col>

        <v-col cols="12" class="mt-6">
          <v-row no-gutters justify="center" align="center" class="ma-2">
            <v-btn
              color="white"
              :loading="isLoading.btnSave"
              class="width-200px mx-auto"
              style="border-radius: 10px !important;"
              @click="updateBillingDetails">
               <span
                 style="color: #4D59FF !important;font-size: 16px !important;"
                 class="font-weight-bold text-uppercase">
                 {{$t('common.buttons.continue')}}
               </span>
            </v-btn>
          </v-row>
        </v-col>

      </v-row>

  </v-card>
</template>

<script>
  // Mixins
  import formRules from "@/mixins/formRules"

  export default {

    name: "BillingDetailsForm",

    mixins: [formRules],

    props: {
      form: {type: Object, default: () => {
      }},
      showBtn: {type: Boolean, default: true}
    },

    components: {
      SelectCountries: () => import("@/components/Common/Inputs/SelectCountries")
    },

    data() {
      return {
        isLoaded: false,
        errors: {
          form: null
        },
        isEnabled: {btnSave: false},
        isLoading: {btnSave: false}
      }
    },

    created() {
      this.isLoaded = true;
      //this.getAccountInfo();
    },

    methods: {

      updateBillingDetails() {

        if (this.$refs.form.validate()) {

          if (this.form.name === "" && this.form.company_name === "") {
            this.errors.form = this.$t('account.profile.errors.NO_NAME');
            return;
          }

          this.isLoading.btnSave = true;

          this.$http.put(`/map/users/${this.$session.get('id')}/billing`, this.form,
          {
            headers:
            {
              Authorization: "Bearer " + this.$session.get('jwt')
            }
          })
          .then(res => {
            this.isLoading.btnSave = false;
            this.errors.form = null;
            this.$emit('nextStep');

          })
          .catch(err => {
            this.isLoading.btnSave = false;
            this.errors.form = this.$t('common.errors.500');
          })
        }
      },

      close() {
        this.$emit('close')
      }
    }

  }
</script>

<style scoped>

</style>